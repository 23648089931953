
import './App.css';
import {useSearchParams} from "react-router-dom";
const tg = window.Telegram.WebApp;

function App() {
    const[searchParams, setSearchParams] = useSearchParams();
    const achievement = searchParams.get('achievement') || '';


    const handleOnClick = async () => {

                let blob = await fetch(`https://bakksyachivment.devall.com.ua/${achievement}.jpg`).then(r => r.blob());

                const data = {

                    files: [
                        new File([blob], 'achievement.jpg', {
                            type: blob.type,
                        }),
                    ]


                };


        if (navigator.share) {
            await navigator.share(data);
        } else {
            tg.openLink(`https://bakksyachivment.devall.com.ua/?achievement=${achievement}`)
        }

    };



  return (
    <div className="App">
      <header className="App-header">
          <div onClick={handleOnClick} className="share"><img alt='minus' src={'./share.png'} className="imgM"/>Share</div>
      </header>
    </div>
  );
}

export default App;
